
import * as THREE from 'three';

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

export default class LoadingManager {

  static async load(models, progress_cb) {
    try {
      const lmanager = new THREE.LoadingManager();
      lmanager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
        progress_cb(url, itemsLoaded, itemsTotal);
      };

      lmanager.onError = function ( url ) {
        console.error(new Error(url));
      };

      await new Promise((fulfill) => {

        lmanager.onLoad = function ( ) {
          fulfill();
        };

        for (let name in models) {
          if (typeof models[name] === "string") {
            const loader = new GLTFLoader(lmanager);
            const dracoLoader = new DRACOLoader(lmanager);
            dracoLoader.setDecoderPath( 'draco/' );
            loader.setDRACOLoader( dracoLoader );
            loader.load(models[name], function (object) {
              models[name] = object;
            });
          } else if (Array.isArray(models[name])) {
            for (let m = 0; m < models[name].length; m++) {
              let model = models[name][m];
              const loader = new GLTFLoader(lmanager);
              const dracoLoader = new DRACOLoader(lmanager);
              dracoLoader.setDecoderPath( 'draco/' );
              loader.setDRACOLoader( dracoLoader );
              loader.load(model, function (object) {
                models[name][m] = object;
              });
            }
          } else {
            console.error(new Error(`Cannot load invalid type ${typeof models[name]}`));
          }
        }
      });

      return models;
    } catch (e) {
      console.error(e.stack);
    }
  }
}
