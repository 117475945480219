

import FlyEngine from 'flyengine'
import LoadingManager from 'flyengine/Loaders/manager.mjs'


const loading_overlay = document.getElementById("loading_overlay");
const loading_indicator = loading_overlay.querySelector("div.progress-bar div.progress-indicator");
const loading_text = loading_overlay.querySelector("div.progress-bar div.progress-text");

const existing_areas = [
  "casino", "town"
]

function randomize_loverlay_back() {
  const n = Math.round(Math.random() * 10 + 1)
  loading_overlay.style.backgroundImage = `url(assets/artworks/art${n}.jpg)`;
}

export default class World {
  static async construct() {
    try {
      let _this = new NewClass();
      
      return _this;
    } catch (e) {
      console.error(e.stack);
    }
  }

  constructor(areas, fly) {
    this.areas = areas;
    for (let area of areas) {
      area.onexit = this.exit_to;
      area.world = this;
    }
    this.fly = fly;
  }



  async exit_to(name) {
    try {
      randomize_loverlay_back();
      loading_overlay.style.display = "";
      loading_overlay.classList.remove("hide");
      const models = await LoadingManager.load({
        area_lods: [
 //         `models/${name}_hd.glb`,
//          `models/${name}_md.glb`,
//          `models/${name}_ld.glb`,
          `models/${name}_hd_1.glb`,
          `models/${name}_md_1.glb`,
//          `models/${name}_ld_1.glb`
        ]
      }, (url, itemsLoaded, itemsTotal) => {
        loading_indicator.style.width = Math.round(itemsLoaded/itemsTotal*100)+"%";
        if (!url.startsWith("blob:")) loading_text.innerText = 'Loading: '+url;
      });

      for (let area of this.areas) {
        if (
          area.name === name &&
          existing_areas.includes(name)
        ) {
          const enter_from = this.current_area.name;
          this.current_area.exit();
          this.current_area = area;
          await this.current_area.enter(this.player, this.fly, models.area_lods, enter_from);
        }
      }

      loading_overlay.classList.add("hide");
      setTimeout(() => {
        loading_overlay.style.display = "none";
      }, 2000);
    } catch (e) {
      console.error(e.stack);
    }
  }


  async start_at(name) {
    try {
      randomize_loverlay_back()
      const models = await LoadingManager.load({
        area_lods: [
//          `models/${name}_hd.glb`,
//          `models/${name}_md.glb`,
//          `models/${name}_ld.glb`,
          `models/${name}_hd_1.glb`,
          `models/${name}_md_1.glb`,
//          `models/${name}_ld_1.glb`
        ],
        slot_machine_lods: [
          `models/objects/slot_machine_hd.glb`,
          `models/objects/slot_machine_md.glb`,
          `models/objects/slot_machine_ld.glb`
        ],
        spaceship_lods: [
          `models/objects/spaceship_hd.glb`
        ],
        motorcycle_lods: [
          `models/objects/motorcycle_hd.glb`
        ],
        character: 'models/deviant.glb',
      }, (url, itemsLoaded, itemsTotal) => {
        loading_indicator.style.width = Math.round(itemsLoaded/itemsTotal*100)+"%";
        if (!url.startsWith("blob:")) loading_text.innerText = 'Loading: '+url;
      });

      this.fly.mem_models = models;
        
      this.player = new FlyEngine.Character(this.fly, models.character);

      for (let area of this.areas) {
        if (area.name === name) {
          this.current_area = area;
          await this.current_area.enter(this.player, this.fly, models.area_lods); 
        }
      }

      setTimeout(() => {
        loading_overlay.classList.add("hide");
        setTimeout(() => {
          loading_overlay.style.display = "none";
        }, 2000);
      }, 5000);
      
    } catch (e) {
      console.error(e.stack);
    }
  }
}
