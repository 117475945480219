
import * as THREE from 'three';

export default class RayCaster extends THREE.Raycaster {
  intersect(object, ray_origin, ray_direction, near, far) {
    if (ray_origin instanceof THREE.Camera) {
      this.setFromCamera(ray_direction, ray_origin);
    } else {
      this.set(ray_origin, ray_direction);
    }
    if (near) this.near = near;
    if (far) this.far = far;
    if (Array.isArray(object)) {
      return this.intersectObjects(object);
    } else {
      return this.intersectObject(object);
    }
  }

  intersect_first(object, ray_origin, ray_direction, near, far) {
    if (ray_origin instanceof THREE.Camera) {
      this.setFromCamera(ray_direction, ray_origin);
    } else {
      this.set(ray_origin, ray_direction);
    }
    if (near) this.near = near;
    if (far) this.far = far;
    const intersections = this.intersectObject(object);
    if (intersections.length > 0) {
      return intersections[0].point;
    } else {
      return null;
    }
  }
}
