

export default class MainWorker  {
  post(channel_id, data, callback) {
    const doit = () => {
      if (!this.channels[channel_id]) this.channels[channel_id] = {};
      if (!this.channels[channel_id].waiting_response) {
        this.channels[channel_id].waiting_response = true;
        this.worker.postMessage({
          channel: channel_id,
          data: data
        });
        if (typeof callback == "function") callback();
      }
    }
    if (!this.channels) {
      const itrv = setInterval(() => {
        if (this.channels) {
          clearInterval(itrv);
          doit();
        }
      }, 100);
    } else {
      doit();
    }
  }

  start(worker) {
    const _this = this;
    this.worker = worker
    this.channels = {};

    this.recvs = {};

    this.worker.onmessage = (data) => {
      if (!data.channel) data = data.data;
      const channel_id = data.channel;
      if (!_this.channels[channel_id]) _this.channels[channel_id] = {};
      _this.channels[channel_id].waiting_response = false;
      if (_this.recvs[channel_id]) _this.recvs[channel_id](data.data);
    };
  }

  recv(channel_id, func) {
    this.recvs[channel_id] = func;
  }
}
