
import * as THREE from 'three';

export default class MeshParser {

  static lod_to_json(lod) {
    const attrs = [];
    const lobj = lod.levels[lod.levels.length-1].object;
    lobj.traverse((child) => {
      if (child.geometry) {
        attrs.push({ 
          indices: child.geometry.index.array,
          vertices: child.geometry.attributes.position.array,
          normals: child.geometry.attributes.normal.array,
          position: lod.position,
          scale: lobj.scale,
          rotation: lobj.rotation.toArray(),
          name: lobj.name
        });
      }
    });

    return attrs;
  }

  static json_to_mesh(refjson) {
    const REF_MESHES = [];
    for (let i = 0; i < refjson.length; i++) {
      for (let m = 0; m < refjson[i].length; m++) {
        const geometry = new THREE.BufferGeometry();
        geometry.setIndex( new THREE.BufferAttribute( refjson[i][m].indices, 1 ) );
        geometry.setAttribute( 'position', new THREE.BufferAttribute( refjson[i][m].vertices, 3 ) );
        geometry.setAttribute( 'normal', new THREE.BufferAttribute( refjson[i][m].normals, 3 ) );
        const material = new THREE.MeshBasicMaterial( { color: 0xff0000, side: THREE.DoubleSide } );
        const mesh = new THREE.Mesh(geometry, material);
        mesh.name = refjson[i][m].name;
        mesh.position.x = refjson[i][m].position.x;
        mesh.position.y = refjson[i][m].position.y;
        mesh.position.z = refjson[i][m].position.z;

        mesh.scale.set(refjson[i][m].scale.x, refjson[i][m].scale.y, refjson[i][m].scale.z);
        mesh.rotation.set(refjson[i][m].rotation[0], refjson[i][m].rotation[1], refjson[i][m].rotation[2], refjson[i][m].rotation[3]);
        mesh.updateMatrix();
        mesh.updateMatrixWorld();

        mesh.matrixAutoUpdate = false;
        REF_MESHES.push(mesh);
      }
    }
    return REF_MESHES;
  }
}
