
import { LoadingManager } from 'three';
import GLTF from './gltf.mjs'

export default class Loaders {
  static Manager = LoadingManager
  static GLTF = class {
    static async load(src, manager) {
      try {
       return await GLTF.load(src, manager)
      } catch (e) {
        console.error(e.stack);
      }
    }
  }
}
 
