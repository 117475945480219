
import * as THREE from 'three';

import RayCaster from '../../Utils/ray-caster.mjs'
import ThreadWorker from '../../Utils/worker_main.mjs'
import MeshParser from '../../Utils/mesh_parser.mjs'

// MOVEMENT
const movement_speed = 5000;
const rotation_velocity = 7;
const obstacle_range = 50;

const gravity = 100;
let acceleration = 2;

// CONSTANTS
const ZERO_VEC2 = new THREE.Vector2(0, 0);
const ZERO_VEC3 = new THREE.Vector3(0, 0);
const one_deg = Math.PI/180;



let WASD = {
  w: false,
  a: false,
  s: false,
  d: false
}

const MOUSE_XY = {
  x: 0,
  y: 0
}

let CHAR_STATE = "idle";
let JUMPIN_UP = false;
let JUMPIN = false;
let LANDED = false;
let JUMP_KEY_UP = false;




let msgno = 0;

const camera_worker = new ThreadWorker();

const camera_controls_worker = new ThreadWorker();
const character_controls_worker = new ThreadWorker();


const gravity_worker = new ThreadWorker();

export default class ThirdPerson {
  constructor(fly, character) {
    this.fly = fly;
    fly.camera.orbit_rotation = new THREE.Vector3();
    fly.camera.orbit_position = new THREE.Vector3(0, 180, 0);
    this.camera = fly.camera;

    this.ray_caster = new RayCaster();

    this.character = character

    this.current_zoom = 400;
    this.waiting = false;

    const _this = this;

    this.character_controls_worker = character_controls_worker;
    this.typing = false;

    camera_controls_worker.start(new Worker(new URL('./workers/camera/controls.mjs', import.meta.url)));
    gravity_worker.start(new Worker(new URL('./workers/character/gravity.mjs', import.meta.url)));



    function update_camera_position() {

      _this.camera.orbit_position.set(
        _this.character.object.position.x,
        _this.character.object.position.y+180,
        _this.character.object.position.z
      );

      _this.camera.orbit_rotation.set(
        _this.camera_rotation.x,
        _this.camera_rotation.y,
        _this.camera_rotation.z
      );

      let ncampos = new THREE.Vector3(0,0,_this.current_zoom);
      ncampos.applyAxisAngle(new THREE.Vector3(1, 0, 0), _this.camera.orbit_rotation.x);
      ncampos.applyAxisAngle(new THREE.Vector3(0, 1, 0), _this.camera.orbit_rotation.y);
      const rdir = ncampos.clone().negate().normalize();

      ncampos.add(_this.camera.orbit_position.clone());

      _this.camera.position.set(ncampos.x, ncampos.y, ncampos.z);
      _this.camera.lookAt(new THREE.Vector3(_this.character.object.position.x, _this.character.object.position.y+180, _this.character.object.position.z));


      const campos = ncampos || new THREE.Vector3(0,0,_this.current_zoom);
      let dlpos = undefined;
      let dimmult = 5000;
      if (_this.camera.orbit_rotation.x < -Math.PI/6) {
        dlpos = new THREE.Vector3();
      } else {
        dlpos = new THREE.Vector3(campos.x-_this.character.object.position.x, 0, campos.z-_this.character.object.position.z).normalize().negate().multiplyScalar(dimmult*0.9);
      }


      const SUN_X = 20000;
      const SUN_Y = 30000;
      const SUN_Z = 20000;

      let dirLight1 = _this.fly.environment.dir_ligth;
      let flareLight = _this.fly.environment.flare_ligth;
        dirLight1.target.position.set(campos.x+dlpos.x, 0, campos.z+dlpos.z);
        dirLight1.target.updateMatrixWorld();
        dirLight1.position.set( campos.x+dlpos.x+SUN_X, SUN_Y, campos.z+dlpos.z+SUN_Z );
        flareLight.position.set( campos.x+dlpos.x+SUN_X, SUN_Y, campos.z+dlpos.z+SUN_Z );
        dirLight1.updateMatrixWorld();

      _this.fly.environment.sun.position.set(campos.x+dlpos.x+SUN_X, SUN_Y, campos.z+dlpos.z+SUN_Z);

      _this.move_vec = new THREE.Vector3()

      camera_worker.post("update", {
        ncampos: ncampos,
        orbit_plus_move: _this.camera.orbit_position.clone(),
        rdir: rdir
      });

    }


    camera_controls_worker.post("init");

    camera_controls_worker.recv("update", (data) => {

      camera_controls_worker.post("update");

      _this.camera_rotation = new THREE.Euler().fromArray(data.camera_rotation);
      character_controls_worker.post("camera_rotation", {
        camera_rotation: _this.camera_rotation ? _this.camera_rotation.toArray() : [0,0,0]
      });

      update_camera_position();


    });


    character_controls_worker.start(new Worker(new URL('./workers/character/controls.mjs', import.meta.url)));



    this.move_vec = new THREE.Vector3();


    character_controls_worker.recv("update", (data) => {
      data.position = new THREE.Vector3().fromArray(data.position);
      _this.character.object.position.set(data.position.x, data.position.y, data.position.z); // NOTE: nchar_pos + move_vec

      _this.char_rotation = new THREE.Euler().fromArray(data.char_rotation);
      _this.character.object.rotation.set(
        _this.char_rotation.x, _this.char_rotation.y, _this.char_rotation.z
      );

      
      update_camera_position()

      character_controls_worker.post("update");
    });


    character_controls_worker.recv("placement_point", (data) => {
      if (_this.placement_mesh)  {
        const placement_point = new THREE.Vector3().fromArray(data);
        _this.placement_mesh.position.set(placement_point.x, placement_point.y, placement_point.z);
      }

      character_controls_worker.post("placement_point");
    });


    this.update_surface()
    this.update_lods()

    camera_worker.start(new Worker(new URL('./workers/camera.mjs', import.meta.url)));
    camera_worker.recv("started", (data) => {
      camera_worker.post("init", {
        ref_meshes: _this.json_lods
      });
    });

    camera_worker.recv("update", (data) => {
      _this.current_zoom = data.czoom;
    });

    window.addEventListener('wheel', (evt) => {
      camera_worker.post("input", {
        deltaY: evt.deltaY
      });
    });




    let cameraDistance = this.current_zoom;
    fly.camera.position.z = cameraDistance;
    fly.scene.add(fly.camera);

    document.addEventListener('mousemove', function(e) {
      let scale = .003;
      MOUSE_XY.x += e.movementX * scale;
      MOUSE_XY.y += e.movementY * scale;

      camera_controls_worker.post("mouse_xy", {
        MOUSE_XY: MOUSE_XY
      }, () => {
        MOUSE_XY.x = MOUSE_XY.y = 0;
      })
    });


    function ANIMATE_CHARACTER(state) {
      if (CHAR_STATE != state) {
        if (JUMPIN) return;
        if (state == "idle" && ( WASD.w || WASD.a || WASD.s || WASD.d ) ) {
          return
        }

        for (const anim in character.animations) {
          character.animations[anim].paused = false;
        }
        character.animations[state].enabled = true;
        character.animations[state].setEffectiveTimeScale( 1 );
        character.animations[state].setEffectiveWeight( 1 );
        character.animations[state].time = 0;
        character.animations[state].crossFadeFrom ( character.animations[CHAR_STATE], .15, false)
        if (state == "jump") {
          acceleration = 5;
          JUMPIN = true;
          JUMPIN_UP = true;
          character_controls_worker.post("jumpin_up", true);
    //      character.animations[state].loop = THREE.LoopOnce;
          setTimeout(() => {
            JUMPIN_UP = false;
            character_controls_worker.post("jumpin_up", false);
          }, character.animations[state].getClip().duration/3*2 * 1000);
          setTimeout(() => {
            JUMPIN = false;
            acceleration = 2;
            if (WASD.w || WASD.a || WASD.s || WASD.d) {
              ANIMATE_CHARACTER("run");
            } else {
              ANIMATE_CHARACTER("idle");
            }
          }, character.animations[state].getClip().duration * 1000)
        }
        character.animations[state].play()
        CHAR_STATE = state;

      }
    }

    _this.ANIMATE_CHARACTER = ANIMATE_CHARACTER;


    document.addEventListener('keydown', (e) => {
      if (!_this.typing) {
        switch (e.keyCode) {
          case 32:
            if (JUMP_KEY_UP) {
              JUMP_KEY_UP = false;
              ANIMATE_CHARACTER("jump")
            }
            break;
          case 87:
            if (!WASD.w) {
              WASD.w = true;
              ANIMATE_CHARACTER("run")
              character_controls_worker.post("wasd", {
                WASD: WASD
              });
            }
            break;
          case 65:
            if (!WASD.a) {
              WASD.a = true;
              ANIMATE_CHARACTER("run")
              character_controls_worker.post("wasd", {
                WASD: WASD
              });
            }
            break;
          case 83:
            if (!WASD.s) {
              WASD.s = true;
              ANIMATE_CHARACTER("run")
              character_controls_worker.post("wasd", {
                WASD: WASD
              });
            }
            break;
          case 68:
            if (!WASD.d) {
              WASD.d = true;
              ANIMATE_CHARACTER("run")
              character_controls_worker.post("wasd", {
                WASD: WASD
              });
            }
            break;
          default:
        }
      }
    });

    document.addEventListener('keyup', (e) => {
      if (!_this.typing) {
        switch (e.keyCode) {
          case 32:
            JUMP_KEY_UP = true;
            break;
          case 87:
            if (WASD.w) {
              WASD.w = false;
              ANIMATE_CHARACTER("idle")
              character_controls_worker.post("wasd", {
                WASD: WASD
              });
            }
            break;
          case 65:
            if (WASD.a) {
              WASD.a = false;
              ANIMATE_CHARACTER("idle")
              character_controls_worker.post("wasd", {
                WASD: WASD
              });
            }
            break;
          case 83:
            if (WASD.s) {
              WASD.s = false;
              ANIMATE_CHARACTER("idle")
              character_controls_worker.post("wasd", {
                WASD: WASD
              });
            }
            break;
          case 68:
            if (WASD.d) {
              WASD.d = false;
              ANIMATE_CHARACTER("idle")
              character_controls_worker.post("wasd", {
                WASD: WASD
              });
            }
            break;
          default:
        }
      }
    });
  }

  update_lods() {
    console.log("UPDATE LODS");
    this.json_lods = [];
    for (let lkey in this.fly.environment.lods) {
      const attrs = MeshParser.lod_to_json(this.fly.environment.lods[lkey]);
      this.json_lods.push(attrs)
    }

    camera_worker.post("init", {
      ref_meshes: this.json_lods
    });

  }


  update_surface() {
    this.json_lods = [];
    for (let surface of this.fly.environment.surface) {
      const attrs = MeshParser.lod_to_json(surface.lod_obj);
      this.json_lods.push(attrs)
    }

    this.camera_rotation = new THREE.Euler().fromArray([0,0,0]);
    character_controls_worker.post("camera_rotation", {
      camera_rotation: [0,0,0]
    });

    character_controls_worker.post("init", {
      surf_meshes: this.json_lods,
      position: this.character.object.position.toArray()
    });

  }

  updateEnvironment() {

  }

  update() {
    const delta = this.fly.delta;


/*
    if (this.camera_rotation) {
      this.camera.orbit_rotation.set(
        this.camera_rotation.x, this.camera_rotation.y, this.camera_rotation.z
      );
    }

    if (this.char_rotation) {
      this.character.object.rotation.set(
        this.char_rotation.x, this.char_rotation.y, this.char_rotation.z
      );
    }
*/
    const ocharpos = this.character.object.position.clone();

      let n_char_pos = new THREE.Vector3(
        this.character.object.position.x+this.move_vec.x,
        this.character.object.position.y,
        this.character.object.position.z+this.move_vec.z
      );


    let move_vec = this.move_vec;


/*    const ddvec = new THREE.Vector3(0, -1, 0);
    const intersects = CAST_RAY(new THREE.Vector3(n_char_pos.x, n_char_pos.y+100, n_char_pos.z), ddvec, 0, 200);
    if (intersects && intersects.length > 0) {
      n_char_pos.y = intersects[0].point.y;
    }*/

/*
    if (JUMPIN_UP) {
      new_y = cur_y+delta*gravity*acceleration;
      if (acceleration > 0) {
        acceleration -= 0.5;
        if (acceleration < 0) acceleration = 0
      }
    } else {
      if (typeof dest_y != "undefined" && dest_y < cur_y) {
        new_y = cur_y-delta*gravity*acceleration;
        if (new_y <= dest_y) {
          LANDED = true;
          new_y = dest_y;
          acceleration = 0;
        } else {
          LANDED = false;
          acceleration += 1;
        }
      } else if (typeof dest_y != "undefined" && dest_y > cur_y) {
        new_y = cur_y+delta*gravity*acceleration;
        if (new_y >= dest_y) {
          LANDED = true;
          new_y = dest_y;
          acceleration = 0;
        } else {
          LANDED = false;
          acceleration += 1;
        }
      }
    }
*/

    this.placed_meshes = [];


  }

  start_placement(tag) {
    character_controls_worker.post("placing", true);

		const placement_mesh = this.placement_mesh = this.fly.mem_models[`${tag}_lods`][0].scene.clone();
    placement_mesh.traverse( function ( child ) {
      child.frustumCulled = false;
      if ( child.isMesh ) {
        child.castShadow = true;
        child.receiveShadow = true;

      }
    });

    placement_mesh.scale.x = placement_mesh.scale.y = placement_mesh.scale.z = 100;
		this.fly.scene.add( placement_mesh );

    const _this = this;

    this.cancel_placement_listener = (e) => {
      document.removeEventListener("click", _this.cancel_placement_listener);
      if (e.button === 2) {
        _this.cancel_placement();
      } else if (e.button === 0) {
        _this.confirm_placement();
      }
    }
    document.addEventListener("click", this.cancel_placement_listener);
  }

  cancel_placement() {
    character_controls_worker.post("placing", false);
		this.fly.scene.remove( this.placement_mesh );
    this.placement_mesh = undefined;
  }


  confirm_placement() {
    character_controls_worker.post("placing", false);
    this.placed_meshes.push(this.placement_mesh);
    this.placement_mesh = undefined;
  }

  start_typing() {
    this.typing = true;
    WASD = {
      w: false,
      a: false,
      s: false,
      d: false
    };
    this.character_controls_worker.post("wasd", {
      WASD: WASD
    });
    this.ANIMATE_CHARACTER("idle")
  }

  end_typing() {
    this.typing = false;
  }

  static async construct(cfg) {
    try {
      return new ThirdPerson();
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

}
 
