
import FlyEngine from 'flyengine'

import World from './world/world.mjs'
import Area from './world/area.mjs'


import ChatBot from './chat/bot.mjs'

// CONFIG ++++++++

const DEBUG_MODE = true;


// Y ORIGIN FOR ALL RAY CASTERS
const raycaster_ys = [
  50
]

// Y RAY CASTER
const rc_spacing = 20;
const rc_coords = [
  [0, 0],
//  [rc_spacing, 0],
//  [0, rc_spacing],
//  [0, -rc_spacing],
//  [-rc_spacing, 0]
]


// XZ RAY CASTER
const xz_ray_length = 160;
const frc_cfgs = [
/*  {
    y: 0.9,
    angle: -Math.PI/8
  },*/
  {
    y: 0.95,
    angle: -Math.PI/12
  },
/*  {
    y: 0.9,
    angle: 0
  },*/
  {
    y: 0.95,
    angle: Math.PI/12
  },
/*  {
    y: 0.9,
    angle: Math.PI/8
  }*/
]



// ---------------
//
//
import * as THREE from 'three';
console.log(THREE.REVISION);


import { RoomEnvironment } from './jsm/environments/RoomEnvironment.js';
import { Lensflare, LensflareElement } from './jsm/objects/Lensflare.js';


import { VertexNormalsHelper } from './jsm/helpers/VertexNormalsHelper.js';


const lmanager = new THREE.LoadingManager();


let dest_y = undefined;
let clight = undefined;

/*
      cam_raycaster.far = ZOOM;
      cam_raycaster.setFromCamera(new THREE.Vector2(0, 0), orbit.camera);
      cam_raycaster.ray.origin = cam_raycaster.ray.origin.clone().sub(orbit.position.clone()).normalize().multiplyScalar(ZOOM).add(orbit.position);
      let cam_intersects = cam_raycaster.intersectObject( WORLD_OBJECT );
*/

const RAY_CASTER = new THREE.Raycaster();
const CAST_RAY = (ray_origin, ray_direction, near, far, extra) => {
  if (near) RAY_CASTER.near = near;
  if (far) RAY_CASTER.far = far;
  if (ray_origin instanceof THREE.Camera) {
    RAY_CASTER.setFromCamera(ray_direction, ray_origin);
  } else {
    RAY_CASTER.set(ray_origin, ray_direction);
  }
  if (extra) extra();
  return RAY_CASTER.intersectObject( WORLD_OBJECT );
}



let CHAR_OBJECT = undefined;
let WORLD_OBJECT = undefined;


const tLoader = new THREE.TextureLoader(lmanager);


let normalHelper = undefined
if (DEBUG_MODE) {
  normalHelper = new THREE.ArrowHelper();
  normalHelper.visible = false;
}
init();

function init() {

  const container = document.createElement( 'div' );
  document.body.appendChild( container );

  const engine = new FlyEngine(container);
  engine.loading_manager = new FlyEngine.Loaders.Manager();

  engine.loading_manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
    console.log("ITEMS TOTAL", itemsTotal);
    loading_indicator.style.width = Math.round(itemsLoaded/itemsTotal*100)+"%";
    loading_text.innerText = 'Loading: '+url;
  };

  engine.loading_manager.onLoad = function ( ) {
    console.log("LOADED");
    loading_overlay.classList.add("hide");
    setTimeout(() => {
      loading_overlay.style.display = "none";
    }, 2000);
  };

  (async () => {
    try {
//      const player_model = await FlyEngine.Loaders.GLTF.load('models/nchar.glb', engine.loading_manager);
//      const player = new FlyEngine.Character(engine, player_model);


      const world = new World([
        new Area("town", {
          scale: 10000
        }),
        new Area("casino", {
          scale: 100
        })
      ], engine);
      await world.start_at("town");

      const controls = engine.controls = new FlyEngine.Controls.ThirdPerson(engine, world.player);

      engine.onupdate = () => {
        world.player.update();
        controls.update();
        if (world.current_area) world.current_area.update();
      }

      engine.start();

      new ChatBot(engine);
    } catch (e) {
      console.error(e.stack);
    }
  })()
}



