
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

export default class GLTF {
  static async load(src, lmanager) {
    try {
      return await new Promise((fulfill) => {
        const loader = new GLTFLoader(lmanager);
        const dracoLoader = new DRACOLoader(lmanager);
        dracoLoader.setDecoderPath( 'draco/' );
        loader.setDRACOLoader( dracoLoader );
        loader.load(src, function (object) {
          fulfill(object);
        });
      });
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  static async base64(str) {
    try {
      const loader = new GLTFLoader(lmanager);
      const dracoLoader = new DRACOLoader(lmanager);
      dracoLoader.setDecoderPath( 'draco/' );
      loader.setDRACOLoader( dracoLoader );
      loader.load(str, function (object) {
        fulfill(object);
      });
    } catch (e) {
      console.error(e.stack);
    }
  }

}
 
