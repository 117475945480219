
import * as THREE from 'three';

export default class Character {
  constructor(engine, object) {
    console.log("CHAR_OBJECT", object)
    this.fly = engine;
    this.mixer = new THREE.AnimationMixer( object.scene );

    this.animations = {
      idle: undefined,
      run: undefined
    }

    this.animations.run = this.mixer.clipAction( object.animations[ 5 ] );
    this.animations.idle = this.mixer.clipAction( object.animations[ 7 ] );
    this.animations.idle.play();
    this.animations.jump = this.mixer.clipAction( object.animations[ 8 ] );


    object.scene.frustumCulled = false;
    object.scene.traverse( function ( child ) {
      if( child.material ) {
          child.material.side = THREE.FrontSide;
        if (child.material.map) {
          child.material.map.flipY = false;
        }
      }

      child.frustumCulled = false;
      if ( child.isMesh ) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });

    const oscale = 100;
    object.scene.scale.x = object.scene.scale.y = object.scene.scale.z = oscale;

    engine.scene.add( object.scene );
    this.object = object.scene;
//    flareLight.target = CHAR_OBJECT;

/*    for (const rcfg of frc_cfgs) {
      const ahlp = DEBUG_MODE ? new THREE.ArrowHelper( new THREE.Vector3(0, rcfg.y, 0.5), new THREE.Vector3(0, raycaster_ys[0], 0), xz_ray_length, "#FF0000" ) : {};
      ahlp.cfg = rcfg;
      CHAR_OBJECT.arrowHelpers.push(ahlp);
    }

    for (const coord of rc_coords) {
      const ahlp = DEBUG_MODE ? new THREE.ArrowHelper( new THREE.Vector3(0, -1, 0), new THREE.Vector3(coord[0]*rc_spacing, raycaster_ys[0], coord[1]*rc_spacing), 100, "#00FF00" ) : {};
      ahlp.coord = coord;
      CHAR_OBJECT.arrowHelpers.push(ahlp);
    }

    if (DEBUG_MODE) {
      for (const ahlp of CHAR_OBJECT.arrowHelpers) {
        scene.add(ahlp);
      }
    }*/
  }

  update(delta) {
    this.mixer.update(this.fly.delta);
  }

  static async construct(cfg) {
    try {
      return new Character();
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

}
 
