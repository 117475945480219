
import ThirdPerson from './3rd-person/3rd-person.mjs'

export default class Controls {
  constructor() {

  }

  static async construct(cfg) {
    try {
      return new Controls();
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  static ThirdPerson = ThirdPerson
}
 
