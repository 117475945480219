
import * as THREE from 'three';

import Stats from 'three/examples/jsm/libs/stats.module.js';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';

import Character from './Character/character.mjs'
import Controls from './Controls/controls.mjs'
import Environment from './Environment/environment.mjs'
import Loaders from './Loaders/loaders.mjs'

export default class FlyEngine {

  static Character = Character
  static Controls = Controls
  static Environment = Environment
  static Loaders = Loaders

  constructor(container, loading_manager) {
    const _this = this;
    this.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 0.01, 1000000 );
    this.loading_manager = loading_manager

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color( 0x000000 );
    this.scene.fog = new THREE.Fog( 0x11112c, 500, 35000 );

    this.mem_models = [];


    this.clock = new THREE.Clock();

    const renderer = this.renderer = new THREE.WebGLRenderer( { antialias: true, logarithmicDepthBuffer: true });
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1;
    renderer.outputEncoding = THREE.sRGBEncoding;
    container.appendChild( renderer.domElement );

    window.addEventListener("beforeuload", (evt) => {
      renderer.renderLists.dispose();
    });

    renderer.domElement.requestPointerLock = renderer.domElement.requestPointerLock || renderer.domElement.mozRequestPointerLock;
    renderer.domElement.onclick = function() {
      renderer.domElement.requestPointerLock();
    }
/*
    loading_overlay.onclick = function() {
      engine.renderer.domElement.requestPointerLock();
    }*/
    document.exitPointerLock = document.exitPointerLock ||
                               document.mozExitPointerLock;


    const textureLoader = new THREE.TextureLoader(this.loading_manager);

    const textureEquirec = textureLoader.load( 'space.png' );
    textureEquirec.mapping = THREE.EquirectangularReflectionMapping;
    textureEquirec.encoding = THREE.sRGBEncoding;
    this.scene.background = textureEquirec;

    const environment = new RoomEnvironment();
    const pmremGenerator = new THREE.PMREMGenerator( renderer );
    this.scene.environment = pmremGenerator.fromScene( environment ).texture;

    this.debug_mode = true;

    if (this.debug_mode) {
      this.stats = new Stats();
      container.appendChild( this.stats.dom );
    }

    function onWindowResize() {
      console.log("RESIZE");
      _this.camera.aspect = window.innerWidth / window.innerHeight;
      _this.camera.updateProjectionMatrix();
      _this.renderer.setSize( window.innerWidth, window.innerHeight );
    }
    window.addEventListener( 'resize', onWindowResize );

    this.update_func = () => {
      _this.update();
    }
  }

  start() {
    this.update();
  }

  update() {
    requestAnimationFrame(this.update_func);
    this.delta = this.clock.getDelta();
    if (this.debug_mode) this.stats.update();

    if (this.onupdate) this.onupdate();

    this.renderer.render(this.scene, this.camera);
  }

  static async construct(cfg) {
    try {
      return new FlyEngine();
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}
 
