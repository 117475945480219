

import * as THREE from 'three';

import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import { Lensflare, LensflareElement } from 'three/examples/jsm/objects/Lensflare.js';

import Loaders from "../Loaders/loaders.mjs"
const LoadingManager = Loaders.Manager


export default class Environment {
  constructor(fly, objects, oscale, child_cb) {
    fly.environment = this;

    this.scale = oscale;

    this.fly = fly;
    const _this = this;

    this.objects = [];
    this.surface = [];

    this.lods = {};
    this.non_lods = [];


    for (let object of objects) {
      const i = this.objects.length;
//      object.scene.scale.set(oscale, oscale, oscale);
//      object.scene.scale.x = object.scene.scale.y = object.scene.scale.z = oscale;
      const rm_list = [];


      const all_children = [];
      object.scene.traverse( function ( child ) {
        
        if (child.geometry) child.geometry.scale(oscale, oscale, oscale);
        child.tposition = new THREE.Vector3();
        child.tposition.x = child.position.x * oscale;
        child.tposition.y = child.position.y * oscale;
        child.tposition.z = child.position.z * oscale;
        child.position.x = child.position.y = child.position.z = 0;
				child.updateMatrix();
        child.updateMatrixWorld();
				child.matrixAutoUpdate = false;

        const mesh_data = child.name.match(/^([0-9]+)_(refterr|struct|suppstruct)($|_)(.*)/);
        if (mesh_data && mesh_data.length > 0) {
          const mesh_index = mesh_data[1];
          const mesh_type = mesh_data[2];
          const mesh_name = mesh_data[4];


          const lod_key = `${mesh_index}_${mesh_type}`;
          if (!_this.lods[lod_key]) _this.lods[lod_key] = [];
          _this.lods[lod_key].push(child);
        } else if (child.name.startsWith("interact_")) {
          _this.non_lods.push(child);
        }

/*        if (child.geometry && child.name != "starting_position") {
          const cc = child.geometry.boundingSphere.center.clone().multiplyScalar(-1);
          const cp = child.geometry.boundingSphere.center.clone();
          child.geometry.translate(cc.x, cc.y, cc.z)
          child.position.set(cp.x, cp.y, cp.z)
        }a*/
        all_children.push(child);

        if( child.material ) {
          child.material.side = THREE.DoubleSide;
          if (child.material.map) {
            child.material.map.flipY = false;
          }

        }

        child.frustumCulled = false;
        if ( child.isMesh ) {
          child.castShadow = true;
          child.receiveShadow = true;

        }

        if ( child.geometry ) {
          child.geometry.computeVertexNormals();

        }

        if (child.name.match(/^([0-9]+)_refterr($|_).*/) && i == 0) {
          _this.surface.push(child);
        }
      });

      for (let ch of all_children) {
        if (i == 0) child_cb(ch)
      }


      this.objects.push(object.scene);
    }
//        WORLD_OBJECT.rotation.set(0, Math.PI/4 ,0);



    const SUN_X = 20000;
    const SUN_Y = 30000;
    const SUN_Z = 20000;

    const dirLight1 = this.dir_ligth = new THREE.DirectionalLight( 0xfff7be, 4 );
    dirLight1.position.set( SUN_X, SUN_Y, SUN_Z );
    dirLight1.castShadow = true;
    let dimmult = 5000;
    dirLight1.shadow.camera.top = dimmult;
    dirLight1.shadow.camera.bottom = -dimmult;
    dirLight1.shadow.camera.left = -dimmult;
    dirLight1.shadow.camera.right = dimmult;
    dirLight1.shadow.camera.near = 0;
    dirLight1.shadow.camera.far = 100000;
    dirLight1.shadow.mapSize.y = dirLight1.shadow.mapSize.x = 4096//2048//8192//4096;
    dirLight1.shadow.radius = 1
    dirLight1.shadow.bias = -0.00005

    const flareLight = this.flare_ligth = new THREE.DirectionalLight( 0xffffdd, 0 );
    flareLight.position.set( SUN_X, SUN_Y, SUN_Z );



    const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x000000, 1 );
    hemiLight.position.set( SUN_X, SUN_Y, SUN_Z );
    hemiLight.lookAt(new THREE.Vector3(0,0,0));
  //        hemiLight.rotation.set(0, 0, -Math.PI/4);
//    fly.scene.add( hemiLight );


    var ambientLight = new THREE.AmbientLight( 0x352d46 );

    this.non_lods.push( ambientLight );

    this.non_lods.push(flareLight);

    this.non_lods.push(dirLight1.target);


    this.non_lods.push( dirLight1 );

    const dltarget = new THREE.Object3D();
    dltarget.position.set(0, 0, 0);
    dirLight1.target = dltarget;
    flareLight.target = dltarget;



    const tLoader1 = new THREE.TextureLoader(fly.loading_manager);

    const textureFlare0 = tLoader1.load( 'models/lensflare/lensflare0_alpha.png' );


    const textureFlare1 = tLoader1.load( 'models/lensflare/lensflare0_alpha2.png' );

    const lensflare = new Lensflare();

    console.log("look at");

    lensflare.addElement( new LensflareElement( textureFlare0, 1200, 0, dirLight1.color ) );
    lensflare.addElement( new LensflareElement( textureFlare0, 1100, 0.01, dirLight1.color ) );
    lensflare.addElement( new LensflareElement( textureFlare0, 1000, 0.02, dirLight1.color ) );
    lensflare.addElement( new LensflareElement( textureFlare0, 900, 0.03, dirLight1.color ) );
    lensflare.addElement( new LensflareElement( textureFlare0, 800, 0.05, dirLight1.color ) );
    lensflare.addElement( new LensflareElement( textureFlare0, 700, 0.1, dirLight1.color ) );
    lensflare.addElement( new LensflareElement( textureFlare0, 600, 0.15, dirLight1.color ) );
    lensflare.addElement( new LensflareElement( textureFlare0, 500, 0.20, dirLight1.color ) );
    lensflare.addElement( new LensflareElement( textureFlare0, 300, 0.25, new THREE.Color( 0xdd77ff) ) );
    lensflare.addElement( new LensflareElement( textureFlare0, 700, 0.4, new THREE.Color( 0xcc88ff) ) );
    lensflare.addElement( new LensflareElement( textureFlare0, 1500, 0.6, new THREE.Color( 0xbb99ff) ) );
    lensflare.addElement( new LensflareElement( textureFlare0, 5000, 1, new THREE.Color( 0xaaaaff) ) );
    lensflare.frustumCulled = false;

    flareLight.add( lensflare );
    

    const tLoader = new THREE.TextureLoader(fly.loading_manager);
    const sun = this.sun = new THREE.Mesh(
      new THREE.PlaneGeometry( 6000, 6000 ),
      new THREE.MeshBasicMaterial({
        transparent: true,
        map: tLoader.load( 'sun.png' ),
        fog: false,
        depthWrite: false
      })
    );

    sun.position.set( SUN_X, SUN_Y, SUN_Z);

    sun.lookAt(this.objects[0].position);

    fly.scene.add( sun );
    //
    //

    const lvl_dist = [
      0,
      8000,
      15000
    ]

    for (let key in this.lods) {
      let lod = new THREE.LOD();
      for (let i = 0; i < _this.lods[key].length; i++) {
        if (i == 0) {
          lod.position.x = _this.lods[key][i].tposition.x;
          lod.position.y = _this.lods[key][i].tposition.y;
          lod.position.z = _this.lods[key][i].tposition.z;
        }
        lod.addLevel( _this.lods[key][i], lvl_dist[i] );
        _this.lods[key][i].lod_obj = lod;
      }
		  lod.updateMatrix();
      this.lods[key] = lod;
      fly.scene.add( lod );
    }

    for (let non_lod of this.non_lods) {
      console.log("NONLOD", non_lod);
      if (non_lod.tposition) {
        non_lod.position.x = non_lod.tposition.x;
        non_lod.position.y = non_lod.tposition.y;
        non_lod.position.z = non_lod.tposition.z;
      }
		  non_lod.updateMatrix();
      fly.scene.add( non_lod );
    }
  }

  update() {

  }

  static async construct(cfg) {
    try {
      return new Environment();
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  destroy() {
    for (let key in this.lods) {
      this.fly.scene.remove( this.lods[key] );
    }

    for (let non_lod of this.non_lods) {
      this.fly.scene.remove( non_lod );
    }

  }


}
 
