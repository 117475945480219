
import FlyEngine from 'flyengine'


import * as THREE from 'three';


export default class Area {

  constructor(name, config) {
    this.name = name
    this.config = config
  }
  
  async enter(player, engine, lod_models, from) {
    try {
      this.player = player;
      this.engine = this.fly = engine;
      this.just_entered = true;
      console.log(`enter ${this.name} from ${from}`);
      const _this = this;

      const town_models = lod_models; // TODO FIX NAMING
      let starting_position = undefined;
      const exits = [];
      delete this.fly.environment;
      this.env = this.fly.environment = new FlyEngine.Environment(this.fly, town_models, this.config.scale, (child) => {
        if (child.name == "config_starting_position") {
          starting_position = child.tposition.clone();
          console.log("START POS", starting_position);
 
          child.parent.remove(child)
        } else if (child.name.startsWith("interact_exit_")) {
          const exit_point = {
            name: child.name.match(/^interact_exit_(.*)$/)[1],
            position: child.tposition.clone().add(child.geometry.boundingSphere.center),
            radius: child.geometry.boundingSphere.radius
          }
          const exit_interval = setInterval(() => {
            //console.log(exit_point, exit_point.position.clone().sub(this.player.object.position).length());
            if (exit_point.position.clone().sub(this.player.object.position).length() < exit_point.radius+200) {
              if (!_this.just_entered) {

                for (let ep of exits) {
                  clearInterval(ep.interval);
                }

                _this.world.exit_to(exit_point.name);
              }
            } else {
              if (_this.just_entered) _this.just_entered = false;
            }
          }, 1000);
          exit_point.interval = exit_interval;
          exits.push(exit_point)
        }
        if (child.name == "Local_BAR") {
         // child.children[3].material.normalMap = null;
          child.children[3].geometry.computeTangents()
          console.log("nmap", child.children[3].material.normalMap);
        }
      });


      if (!starting_position) {
        starting_position = new THREE.Vector3(7200.446128845215, 83.99084210395813, 15401.747226715088)
      }

      if (from) {
        console.log("FROM", exits);
        for (let exit_point of exits) {
          console.log(exit_point.name, from);
          if (exit_point.name === from) {
            console.log("SET FROM POS", exit_point);
            this.player.object.position.x = exit_point.position.x;
            this.player.object.position.y = exit_point.position.y;
            this.player.object.position.z = exit_point.position.z;
          }
        }
      } else {
        this.player.object.position.x = starting_position.x;
        this.player.object.position.y = starting_position.y;
        this.player.object.position.z = starting_position.z;

          console.log("SET STARTING", this.player.object.position);
      }

      if (this.fly.controls) {
        this.fly.controls.update_lods();
        this.fly.controls.update_surface();
      }
      
    } catch (e) {
      console.error(e);
    }
  }

  update() {
    if (this.env) this.env.update();
  }

  exit() {
    this.env.destroy();
    delete this.env;
  }

}
