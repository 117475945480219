

import ThreadWorker from 'flyengine/Utils/worker_main.mjs'

export default class ChatBot {
  constructor(fly) {
    const _this = this;

    this.ready = false;

    this.element = document.getElementById("chat-box");
    this.e_messages = this.element.querySelector("div.messages");

//    this.message("Starting virtual assistant...");
    this.message("Loading God particle...");


    const chat_worker = new ThreadWorker();

//    this.message("Loading scripts and libraries...");
    this.message("Loading Master model...");
    chat_worker.start(new Worker(new URL('./worker.mjs', import.meta.url)));

    chat_worker.recv("status", (data) => {
      if (data.ready) _this.ready = true;

      chat_worker.post("status");
      _this.message(data.msg);
      if (data.resp_class === "selected") {
        if (data.class_list.includes("place_slot_machine")) {
          fly.controls.start_placement("slot_machine");
        } else if (data.class_list.includes("place_motorcycle")) {
          fly.controls.start_placement("motorcycle");
        } else if (data.class_list.includes("place_spaceship")) {
          fly.controls.start_placement("spaceship");
        }
      }
    });


    this.e_textarea = this.element.querySelector("div.input textarea");

    window.addEventListener('keydown', function(event) {
      if (event.key === "Enter" || event.keyCode === 13) {
        event.preventDefault();
        if (_this.e_textarea !== document.activeElement) {
          setTimeout(() => {
            fly.controls.start_typing();
            _this.e_textarea.focus();
          });
        } else {
          setTimeout(() => {
            fly.controls.end_typing();
            _this.e_textarea.blur();
          });
        }
      }
    })

    this.e_textarea.addEventListener("keydown", function(event) {
      if (event.key === "Enter" || event.keyCode === 13) {
        event.preventDefault();
        if (_this.e_textarea.value.length > 0 && _this.ready) {
          chat_worker.post("message", _this.e_textarea.value);
          _this.message(_this.e_textarea.value, true);
          _this.e_textarea.value = "";
        }
        return false;
      }
    })
  }

  message(msg, outgoing) {
    const status_msg_div = document.createElement("div");
    status_msg_div.innerHTML = msg;
    status_msg_div.classList.add(outgoing ? "outgoing" : "incoming");
    this.e_messages.appendChild(status_msg_div);
    this.e_messages.scrollTop = this.e_messages.scrollHeight;
  }
}

